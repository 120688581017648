<template>
  <div>
    <div class="row">
      <div class="col-md-3 col-sm-12 ml-auto">
        <router-link to="/startup/add" replace>
          <base-button class="btn-block" type="danger">
            <i class="fas fa-plus"></i>
            Add Startup</base-button
          >
        </router-link>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <el-table :data="tableData">
          <el-table-column min-width="50" label="#" align="center">
            <div class="photo" slot-scope="{ row }">
              <img :src="row.image" alt="Table image" />
            </div>
          </el-table-column>
          <el-table-column min-width="150" label="Name">
            <div class="startup-name" slot-scope="{ row }">
              <router-link
                v-bind:to="{
                  name: 'Startup-Detail',
                  params: {
                    startup_id: row.name,
                    startup_doc_id: row.id,
                    program_id: row.program_id,
                  },
                }"
              >
                {{ row.name }}
              </router-link>
            </div>
          </el-table-column>
          <el-table-column min-width="150" prop="type" label="Type">
          </el-table-column>
          <el-table-column
            min-width="100"
            prop="members"
            align="center"
            label="Members"
          >
          </el-table-column>
          <el-table-column min-width="200" align="right" label="Progress">
            <div slot-scope="{ row }">
              <base-progress
                class="startup-progress"
                label="Progress"
                value-position="right"
                type="danger"
                :value="row.progress"
              />
            </div>
          </el-table-column>
          <el-table-column
            min-width="100"
            header-align="right"
            align="right"
            label="Options"
          >
            <div slot-scope="{ row, $index }" class="text-right table-actions">
              <el-tooltip
                content="Edit"
                effect="light"
                :open-delay="300"
                placement="top"
              >
                <router-link
                  :to="{
                    name: 'Startup-Edit',
                    params: { startup_id: row.id },
                  }"
                  replace
                >
                  <base-button icon size="sm" class="btn-link">
                    <i class="fas fa-edit"></i>
                  </base-button>
                </router-link>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";
import db from "@/components/Firebase/firebaseInit";
import VueMoment from "vue-moment";
import { BaseProgress } from "src/components/index";

export default {
  components: {
    BaseProgress,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VueMoment,
  },
  data() {
    return {
      tableData: [],
    };
  },
  created() {
    let tableData = this.tableData;
    if(this.$store.state.models.type == 'program' || this.$store.state.models.type == 'partner'){
   
    db.collection("programs").where('email', '==', this.$store.state.models.email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let programID = doc.id;
          db.collection("startups").where('programID', '==', programID)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                let progress;
                if(doc.data().totalMilestones == 0){
                  progress = 0;
                }else{
                  progress =
                  (doc.data().completedMilestones * 100) / doc.data().totalMilestones;
                }
                let imgURL;
                if (doc.data().imgURL == null) {
                  imgURL = "img/placeholder.jpg";
                } else {
                  imgURL = doc.data().imgURL;
                }
                const data = {
                  id: doc.id,
                  name: doc.data().name,
                  type: doc.data().type,
                  members: doc.data().meambers,
                  progress: progress.toFixed(0),
                  image: imgURL,
                  program_id: doc.data().programID,
                };
                tableData.push(data);
              });
            });

        })
      });

    }else{
    db.collection("startups")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let progress;
          if(doc.data().totalMilestones == 0){
            progress = 0;
          }else{
            progress =
            (doc.data().completedMilestones * 100) / doc.data().totalMilestones;
          }
          let imgURL;
          if (doc.data().imgURL == null) {
            imgURL = "img/placeholder.jpg";
          } else {
            imgURL = doc.data().imgURL;
          }
          const data = {
            id: doc.id,
            name: doc.data().name,
            type: doc.data().type,
            members: doc.data().meambers,
            progress: progress.toFixed(0),
            image: imgURL,
            program_id: doc.data().programID,
          };
          this.tableData.push(data);
        });
      });
    }
  },
  methods: {},
};
</script>
<style lang="scss">
.startup-progress {
  div.progress {
    .progress-bar {
      background-image: linear-gradient(
        to right,
        rgb(119, 175, 237),
        rgb(68, 215, 182)
      );
    }
  }
}
.startup-name {
  a {
    font-weight: bold;
  }
}
</style>
