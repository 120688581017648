var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 col-sm-12 ml-auto"},[_c('router-link',{attrs:{"to":"/startup/add","replace":""}},[_c('base-button',{staticClass:"btn-block",attrs:{"type":"danger"}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Add Startup")])],1)],1)]),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('el-table',{attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"min-width":"50","label":"#","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"photo"},[_c('img',{attrs:{"src":row.image,"alt":"Table image"}})])}}])}),_c('el-table-column',{attrs:{"min-width":"150","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"startup-name"},[_c('router-link',{attrs:{"to":{
                name: 'Startup-Detail',
                params: {
                  startup_id: row.name,
                  startup_doc_id: row.id,
                  program_id: row.program_id,
                },
              }}},[_vm._v(" "+_vm._s(row.name)+" ")])],1)}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"type","label":"Type"}}),_c('el-table-column',{attrs:{"min-width":"100","prop":"members","align":"center","label":"Members"}}),_c('el-table-column',{attrs:{"min-width":"200","align":"right","label":"Progress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return _c('div',{},[_c('base-progress',{staticClass:"startup-progress",attrs:{"label":"Progress","value-position":"right","type":"danger","value":row.progress}})],1)}}])}),_c('el-table-column',{attrs:{"min-width":"100","header-align":"right","align":"right","label":"Options"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
              var $index = ref.$index;
return _c('div',{staticClass:"text-right table-actions"},[_c('el-tooltip',{attrs:{"content":"Edit","effect":"light","open-delay":300,"placement":"top"}},[_c('router-link',{attrs:{"to":{
                  name: 'Startup-Edit',
                  params: { startup_id: row.id },
                },"replace":""}},[_c('base-button',{staticClass:"btn-link",attrs:{"icon":"","size":"sm"}},[_c('i',{staticClass:"fas fa-edit"})])],1)],1)],1)}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }