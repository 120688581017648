<template>
  <div>
    <div class="row">
      <div v-if="type == 1" class="col-md-3 col-sm-12 ml-auto">
        <router-link to="/partners/add" replace>
          <base-button class="btn-block" type="danger">
            <i class="fas fa-plus"></i>
            Add new partner</base-button
          >
        </router-link>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-6" v-for="card in statsCards" :key="card.title">
        <router-link v-if="type == 1"
          v-bind:to="{
            name: 'Partners-Detail',
            params: { partner_id: card.id },
          }"
        >
          <stats-card-img
            :title="card.title"
            :sub-title="card.subTitle"
            type="danger"
            :icon="card.logo"
          >
            <!-- <div slot="footer" v-html="card.footer"></div> -->
          </stats-card-img>
        </router-link>
          <!-- <stats-card v-if="type != 1"
            :title="card.title"
            :sub-title="card.subTitle"
            type="danger"
            icon="lni lni-users"
          >
            <div slot="footer" v-html="card.footer"></div>
          </stats-card> -->
      </div>
    </div>


  </div>
</template>
<script>
import StatsCardImg from "src/components/Cards/StatsCardImg";
import db from "@/components/Firebase/firebaseInit";
const data = [
  { dateLabel: 'January 2017', title: 'Gathering Information' },
  { dateLabel: 'February 2017', title: 'Planning' },
  { dateLabel: 'March 2017', title: 'Design' },
  { dateLabel: 'April 2017', title: 'Content Writing and Assembly' },
  { dateLabel: 'May 2017', title: 'Coding' },
  { dateLabel: 'June 2017', title: 'Testing, Review & Launch' },
  { dateLabel: 'July 2017', title: 'Maintenance' }
];

export default {
  components: {
    StatsCardImg,
  },
  data() {
    return {
      statsCards: [],
      steps: data
    };
  },
  created() {
    db.collection("partners")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let startups;
          if (doc.data().startups == null) {
            startups = 0;
          } else {
            startups = doc.data().startups;
          }

          let programs;
          if (doc.data().programs == null) {
            programs = 0;
          } else {
            programs = doc.data().programs;
          }

          const data = {
            logo: doc.data().logo,
            id: doc.id,
            title: doc.data().name,
            subTitle: doc.data().email,
            footer:
              "<b>" +
              programs +
              "</b> Programs, <b> " +
              startups +
              "</b> Startups",
          };

          this.statsCards.push(data);
        });
      });
  },
    mounted() {
      console.log('here we are')
                console.log(this.statsCards);

    // var swiper = new Swiper('.swiper-container', {
    //   //pagination: '.swiper-pagination',
    //   slidesPerView: 7,
    //   paginationClickable: true,
    //   grabCursor: true,
    //   paginationClickable: true,
    //   nextButton: '.next-slide',
    //   prevButton: '.prev-slide',
    // });    
  },
  methods: {},
  computed: {
    type(){
      let x = this.$store.state.models.type;
      let y;
      if(x == 'admin'){
        y = 1;
      }else if(x == 'program'){
        y = 2;
      }else if(x == 'partner'){
        y = 3;
      }else if(x == 'startup'){
        y = 4;
      }
       return y;
    },
  }
};
</script>
<style>

</style>
