<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Stacked Form">
        <h4 slot="header" class="card-title">Add New Product</h4>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div>
            <div class="col-md-8">
               <ValidationProvider
                  name="Name"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Name"
                  v-model="model.name"
                  type="text"
                  placeholder="Enter product name"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
               </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                  name="Website"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Website"
                  v-model="model.website"
                  type="text"
                  placeholder="Enter product website"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                  name="Phone"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Phone"
                  v-model="model.phone"
                  type="text"
                  placeholder="Enter product phone"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                  name="Email"
                  rules="required|email"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Email"
                  v-model="model.email"
                  type="text"
                  placeholder="Enter product email"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
                <textarea class="form-text form-control" v-model="model.description" placeholder="Enter program description" label="Description" name="" id="" cols="30" rows="10"></textarea>
                <br>
            </div>
            <div class="col-md-8">
                <base-button class="mt-4" id="submit" native-type="submit" type="danger"
                >Add</base-button
                >
            </div>
          </div>
        </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import db from '@/components/Firebase/firebaseInit'
import firebase from 'firebase';
import swal from "sweetalert2";
import { DatePicker } from 'element-ui';
import {
  TagsInput
} from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, numeric, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);

export default {
    components: {
     TagsInput,
     [DatePicker.name]: DatePicker,
    },
  data() {
    return {
        model: {
            name: null,
            website: null,
            phone: null,
            email: null,
            description: null,
            tags: ['business'],
        }
    };
  },
  methods: {
      submit(){
        document.getElementById('submit').disabled=true;
        let idDate = Number(new Date().valueOf());

        // add the data to db
        db.collection('products').add({
            name: this.model.name,
            website: this.model.website,
            email: this.model.email,
            phone: this.model.phone,
            program_id: this.$route.params.program_id,
            startup_id: this.$route.params.startup_id,
            description: this.model.description,
            time: idDate

        })
        .then(docRef => {
            console.log('Successfully added: ', docRef.id)
            // alert("Succefully Added Data");
            swal.fire({
              title: `Success!`,
              text: "You have succesfully added product",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger btn-fill"
              },
              icon: "success"
            });
            this.$router.push('/startups')
        })
        .catch(error => {
            console.error('Error adding Data: ', error)
        })

      }
  }
};
</script>
<style></style>
