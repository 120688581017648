<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Stacked Form">
        <h4 slot="header" class="card-title">Invite Startup</h4>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Program Name"
                    rules="required"
                    v-slot="{ errors }"
              >
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="Select Program"
                  v-model="model.program"
                  :error="errors[0]">
                  <el-option
                    v-for="option in partners"
                    class="select-danger"
                    :value="option.value"
                    :label="option.name"
                    :key="option.id"
                  >
                  </el-option>
                </el-select>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Email"
                    rules="required|email"
                    v-slot="{ passed, failed, errors }"
              >
                <base-input
                required
                label="Email"
                v-model="model.email"
                type="text"
                placeholder="Enter startup email"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
                <!-- <base-button class="mt-4" id="submit" native-type="submit" type="danger"
                >Invite</base-button
                > -->
              <div v-if="loginButton == true">
                <base-button
                  type="danger"
                  @click="submit"
                  class="login-btn"
                  size="lg"
                  block
                  disabled
                >                
                    <svg width="20" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                    <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                      <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                        <animateTransform
                          attributeName="transform"
                          dur="2s"
                          type="rotate"
                          from="0 50 48"
                          to="360 50 52"
                          repeatCount="indefinite" />
                        
                      </circle>
                    </svg>
                </base-button>
              </div>
              <div v-if="loginButton == false">
                <base-button
                  @click="submit"
                  type="danger"
                  class="login-btn"
                  size="lg"
                  block
                >                
                Invite
                </base-button>
              </div>
            </div>
          </div>
        </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import { DatePicker, Select, Option } from 'element-ui';
import db from '@/components/Firebase/firebaseInit'
import firebase from 'firebase';
import swal from "sweetalert2";
import { extend } from "vee-validate";
import { required, email, numeric, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      loginButton: false,
      partners: [],
        model: {
            name: null,
            program: null,
            type: null,
            address: null,
            email: null,
            phone: null,
            description: null,
            valuationn: null,
            initialCap: null,
            profits: null,
            jobs: null,
            assets: null
        }
    };
  },
  created(){
    db.collection('programs').get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const data = {
          'id': doc.id,
          'name': doc.data().name,
          'partnerID': doc.data().partnerID,
          'partnerName': doc.data().partnerName,
          'value': doc.data().name + "--" + doc.id
        }
        this.partners.push(data)
      })
    })
  },
  methods: {
    submit () {
      this.emailSubscribe = false;
      this.loginButton = true;
      // document.getElementById('submit').disabled=true;
      const axios = require('axios');
      const qs = require('querystring');
      const url =
        'https://us-central1-ice180-beta.cloudfunctions.net/sendMail';

          const requestBody = {
            email: this.model.email,
            program: this.model.program
          }

          const config = {
            headers: {
              // 'Content-Type': 'application/x-www-form-urlencoded',

            }
          }

          axios.post(url, qs.stringify(requestBody), config)
            .then((result) => {
              // console.log(result.statusCode + " is the status");
              alert('We have sent you a link to manage your account. Go to your email and follow the link.');
              this.loginButton = false;
              this.email = '';
            })
            .catch((err) => { 
              console.log(err + ' is the error')
              alert('Error subscribing');
               this.loginButton = false;
            })
    },
  }
};
</script>
<style></style>
