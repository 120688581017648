<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Stacked Form">
        <h4 slot="header" class="card-title">Add New Action</h4>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div>
            <div class="col-md-8">
              <ValidationProvider
                  name="Name"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Name"
                  v-model="model.message"
                  type="text"
                  placeholder="Enter action message"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                  name="Action Link"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Link"
                  v-model="model.link"
                  type="text"
                  placeholder="Enter action link"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                  name="Date"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
              >
                <el-date-picker
                    type="date"
                    label="Deadline"
                    placeholder="Deadline Date"
                    v-model="model.deadline"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </el-date-picker>
              </ValidationProvider>
            </div>
            <br>
            <div class="col-md-8">
                <tags-input label="Tags" v-model="model.tags"></tags-input>
            </div>
            <div class="col-md-8">
                <textarea class="form-text form-control" v-model="model.description" placeholder="Enter program description" label="Description" name="" id="" cols="30" rows="10"></textarea>
                <br>
            </div>
            <div class="col-md-8">
                <base-button class="mt-4" id="submit" native-type="submit" type="danger"
                >Add</base-button
                >
            </div>
          </div>
        </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import db from '@/components/Firebase/firebaseInit'
import firebase from 'firebase';
import swal from "sweetalert2";
import { DatePicker } from 'element-ui';
import {
  TagsInput
} from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, numeric, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);

export default {
    components: {
     TagsInput,
     [DatePicker.name]: DatePicker,
    },
  data() {
    return {
        model: {
            name: null,
            message: null,
            link: null,
            deadline: null,
            description: null,
            tags: ['business'],
        }
    };
  },
  methods: {
      submit(){
        document.getElementById('submit').disabled=true;
        let idDate = Number(new Date().valueOf());



        // add the data to db
        db.collection('actions').add({
            message: this.model.message,
            link: this.model.link,
            deadline: this.model.deadline,
            tags: this.model.tags,
            program_id: this.$route.params.program_id,
            startup_id: this.$route.params.startup_id,
            description: this.model.description,
            time: idDate,
            status: 'created'

        })
        .then(docRef => {

            console.log('Successfully added: ', docRef.id)
            // alert("Succefully Added Data");
            // this.emailNotify();


          db.collection('startups')
          .where('name', '==', this.$route.params.startup_id)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              console.log(doc.data().email + ' is the email onsde');
              this.emailNotify(doc.data().email);
            })
          })

            
            swal.fire({
              title: `Success!`,
              text: "You have succesfully added action",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger btn-fill"
              },
              icon: "success"
            });
            this.$router.push('/startups')
        })
        .catch(error => {
            console.error('Error adding Data: ', error)
        })

      },
      emailNotify (personelEmail) {
        console.log(personelEmail + ' is the email');
        const axios = require('axios');
        const qs = require('querystring');
        const url =
          'https://us-central1-ice180-beta.cloudfunctions.net/emailNotify';

            const requestBody = {
              email: personelEmail,
            }

            const config = {
              headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',

              }
            }

            axios.post(url, qs.stringify(requestBody), config)
              .then((result) => {
                console.log(result.statusCode + " is the status");
                // alert('We have sent you a link to manage your account. Go to your email and follow the link.');
                this.emailSubscribe = true;
                this.email = '';
              })
              .catch((err) => { 
                console.log(err + ' is the error')
                // alert('Error subscribing');
                this.emailSubscribe = true;
              })
      }
  }
};
</script>
<style></style>
