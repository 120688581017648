<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <card type="contributions">
            <div class="row">
              <div class="col-md-3">
                    <img :src="logo" :alt="name">
                </div>
                <div class="col-md-8">
                    <h1 class="card-title">{{this.name}}</h1>
                    <h3 class="card-category">{{this.phone}}<br>
                    {{this.email}}<br>
                    {{this.address}}</h3>
                    <p>
                      {{this.description}}
                    </p>
                </div>
                <div class="col-md-2 ml-auto">
                          <router-link
                            :to="{
                                  name: 'Partners-Edit',
                                  params: { partner_id: $route.params.partner_id },
                                }"
                                  replace
                            >
                              <base-button
                                icon
                                size="sm"
                                class="btn-link">
                                  <i class="fas fa-edit"></i>
                              </base-button>
                          </router-link>

              <base-button
                icon
                @click.native="deleteItem()"
                size="sm"
                class="btn-link"
              >
                <i class="fas fa-trash"></i>
              </base-button>
            </div>
          </div>
          <!-- <hr /> -->

          <!-- <template slot="footer">
            <div class="row">
              <div class="col-lg-6 col-md-8 ml-auto mr-auto">
                <div class="card-stats justify-content-center">
                  <base-switch
                    v-model="allContributions"
                    on-text="ON"
                    off-text="ON"
                  ></base-switch>
                  <span>All public contributions</span>
                </div>
              </div>
              <div class="col-lg-6 col-md-8 ml-auto mr-auto">
                <div class="card-stats justify-content-center">
                  <base-switch
                    v-model="pastWeekContributions"
                    on-text="ON"
                    off-text="ON"
                  ></base-switch>
                  <span>Past week contributions</span>
                </div>
              </div>
            </div>
          </template> -->
        </card>
      </div>
      <div class="col-md-12">
        <card class="card-plain">
          <!-- <template slot="header">
            <h5 class="card-category">Navigation Pills</h5>
            <h3 class="card-title">Horizontal Tabs</h3>
          </template> -->

          <tabs type="primary">
            <tab-pane label="Startups">
              <div class="row">
                <div class="col-md-1 ml-auto">
                  <router-link to="/startup/add" replace>
                    <base-button type="danger" round icon>
                      <i class="fas fa-plus"></i>
                    </base-button>
                  </router-link>
                </div>
                <div class="col-sm-12">
                  <el-table :data="startupData">
                    <el-table-column min-width="50" label="#" align="center">
                      <div class="photo" slot-scope="{ row }">
                        <img :src="row.image" alt="Table image" />
                      </div>
                    </el-table-column>
                    <el-table-column min-width="150" label="Name">
                      <div slot-scope="{ row }">
                        <router-link
                          v-bind:to="{
                            name: 'Startup-Detail',
                            params: {
                              startup_id: row.name,
                              startup_doc_id: row.id,
                              program_id: row.program_id,
                            },
                          }"
                        >
                          {{ row.name }}
                        </router-link>
                      </div>
                    </el-table-column>
                    <el-table-column min-width="150" prop="type" label="Type">
                    </el-table-column>
                    <el-table-column
                      min-width="100"
                      prop="members"
                      align="center"
                      label="Members"
                    >
                    </el-table-column>
                    <el-table-column
                      min-width="200"
                      align="right"
                      label="Progress"
                    >
                      <div slot-scope="{ row }">
                        <base-progress
                          label="Progress"
                          value-position="right"
                          type="danger"
                          :value="row.progress"
                        />
                      </div>
                    </el-table-column>
                    <el-table-column
                      min-width="100"
                      header-align="right"
                      align="right"
                      label="Options"
                    >
                      <div
                        slot-scope="{ row, $index }"
                        class="text-right table-actions"
                      >
                        <el-tooltip
                          content="Edit"
                          effect="light"
                          :open-delay="300"
                          placement="top"
                        >
                          <router-link
                            :to="{
                              name: 'Startup-Edit',
                              params: { startup_id: row.id },
                            }"
                            replace
                          >
                            <base-button icon size="sm" class="btn-link">
                              <i class="fas fa-edit"></i>
                            </base-button>
                          </router-link>
                        </el-tooltip>
                      </div>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </tab-pane>

            <tab-pane label="Programs">
              <div class="row">
                <div class="col-md-1 ml-auto">
                  <router-link to="/programs/add" replace>
                    <base-button type="danger" round icon>
                      <i class="fas fa-plus"></i>
                    </base-button>
                  </router-link>
                </div>
                <div class="col-sm-12">
                  <el-table :data="programData">
                    <el-table-column min-width="150" label="Program">
                      <div slot-scope="{ row }">
                        <router-link
                          v-bind:to="{
                            name: 'Programs-Detail',
                            params: { program_id: row.id },
                          }"
                        >
                          {{ row.program }}
                        </router-link>
                      </div>
                    </el-table-column>
                    <!-- <el-table-column min-width="200" prop="incubated" label="Incubated"> -->
                    <!-- </el-table-column> -->
                    <el-table-column
                      min-width="150"
                      align="center"
                      label="Deadline"
                    >
                      <div slot-scope="{ row }">
                        {{ row.end.toDate() | moment("MMM YYYY") }}
                      </div>
                    </el-table-column>
                    <el-table-column
                      min-width="150"
                      header-align="right"
                      align="right"
                      label="Options"
                    >
                      <div
                        slot-scope="{ row, $index }"
                        class="text-right table-actions"
                      >
                        <el-tooltip
                          content="Edit"
                          effect="light"
                          :open-delay="300"
                          placement="top"
                        >
                          <router-link
                            :to="{
                              name: 'Program-Edit',
                              params: { program_id: row.id },
                            }"
                            replace
                          >
                            <base-button icon size="sm" class="btn-link">
                              <i class="fas fa-edit"></i>
                            </base-button>
                          </router-link>
                        </el-tooltip>
                      </div>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </tab-pane>
          </tabs>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { TabPane, Tabs, Collapse, CollapseItem } from "src/components";
import { Table, TableColumn } from "element-ui";
import { ImageUpload } from "src/components/index";
import db from "@/components/Firebase/firebaseInit";
import firebase from "firebase";
import swal from "sweetalert2";

import { BaseProgress } from "src/components/index";

export default {
  components: {
    BaseProgress,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    TabPane,
    Tabs,
    Collapse,
    ImageUpload,
    CollapseItem,
  },
  data() {
    return {
      name: null,
      phone: null,
      type: null,
      email: null,
      address: null,
      description: null,
      images: {
        regular: null,
      },
      activeName: "first",
      startupData: [],
      programData: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    db.collection("partners")
      .where(
        firebase.firestore.FieldPath.documentId(),
        "==",
        to.params.partner_id
      )
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          next((vm) => {
            vm.id = doc.id;
            vm.name = doc.data().name;
            vm.type = doc.data().type;
            vm.address = doc.data().address;
            vm.email = doc.data().email;
            vm.phone = doc.data().phone;
            vm.description = doc.data().description;
            if (doc.data().logo == null) {
              vm.logo = "img/image_placeholder.jpg";
            } else {
              vm.logo = doc.data().logo;
            }
          });
        });
      });

    db.collection("partners/" + to.params.partner_id + "/programs")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          next((vm) => {
            let incubated;
            if (doc.data().startups == null) {
              incubated = 0;
            } else {
              incubated = doc.data().startups;
            }
            const data = {
              id: doc.id,
              program: doc.data().name,
              incubated: incubated,
              end: doc.data().end,
            };
            vm.programData.push(data);

            db.collection("startups")
              .where("programID", "==", doc.data().programID)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  let progress;
                  if (doc.data().totalMilestones == 0) {
                    progress = 0;
                  } else {
                    progress =
                      (doc.data().completedMilestones * 100) /
                      doc.data().totalMilestones;
                  }
                  let imgURL;
                  if (doc.data().imgURL == null) {
                    imgURL = "img/placeholder.jpg";
                  } else {
                    imgURL = doc.data().imgURL;
                  }
                  const data = {
                    id: doc.id,
                    name: doc.data().name,
                    type: doc.data().type,
                    members: doc.data().meambers,
                    progress: progress.toFixed(0),
                    image: imgURL,
                    program_id: doc.data().programID,
                  };
                  vm.startupData.push(data);
                });
              });
          });
        });
      });
  },
  methods: {
    deleteItem() {
      swal
        .fire({
          title: "Are you sure?",
          text: `Please confirm you want to delete. you cant revert once you have deleted the item.`,
          //   icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: "Confirm",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            //DB
            // Delete the Show
            let programID = this.$route.params.program_id;
            db.collection("partners")
              .where(
                firebase.firestore.FieldPath.documentId(),
                "==",
                this.$route.params.partner_id
              )
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  doc.ref.delete();

                  console.log("Module Deleted");
                });
              });
            //DB
            swal.fire({
              title: "Completed!",
              text: "You have deleted the item",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            this.$router.push("/partners");
          }
        });
    },
    onImageChange(file) {
      this.images.regular = file;
    },
  },
};
</script>
<style lang="scss"></style>
